<template>
  <v-app>
    <!-- Overlay pour la perte de connexion -->
    <v-overlay
      v-if="!isOnline && $route.name !== 'LandingPage'"
      absolute
      class="connection-lost-overlay"
    >
      <v-card class="pa-4 text-center" max-width="400">
        <v-icon size="64" color="orange">mdi-cloud-off-outline</v-icon>
        <h3 class="mt-2">Oops, connexion perdue !</h3>
        <p>On dirait que vous êtes hors ligne. Veuillez vérifier votre connexion internet pour continuer à profiter de nos services.</p>
        <v-btn color="primary" @click="retryConnection">Réessayer</v-btn>
      </v-card>
    </v-overlay>

    <v-overlay v-if="loading">
      <v-progress-circular indeterminate color="red" size="64"></v-progress-circular>
    </v-overlay>

    <Navbar v-if="isAuthenticated" />

    <v-main :class="{'authenticated-main': isAuthenticated}">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>

    <CookieBanner
      v-if="showCookieBanner"
      @cookies-accepted="onCookiesAccepted"
      @cookies-declined="onCookiesDeclined"
    />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';
import Navbar from './components/NavBar.vue';
import CookieBanner from './components/CookieBanner.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    CookieBanner,
  },
  data() {
    return {
      showCookieBanner: false,
      isOnline: navigator.onLine, // Détection initiale de la connexion
    };
  },
  computed: {
    ...mapState(['isAuthenticated', 'loading', 'badges', 'userRole']),
  },
  watch: {
    isAuthenticated(newValue) {
      const allowedRoutes = [
        'LandingPage',
        'ResetPassword',
        'ForgotPassword',
        'PolitiqueDeConfidentialite',
        'Feedback',
        'Register',
      ];
      if (!newValue && !allowedRoutes.includes(this.$route.name)) {
        this.$router.push('/login');
      }
    },
    badges: {
      handler(newBadges, oldBadges) {
        if (newBadges.length > oldBadges.length) {
          const newBadge = newBadges[newBadges.length - 1];
          this.showBadgeToast(newBadge);
        }
      },
      deep: true,
    },
  },
  methods: {
    showBadgeToast(badge) {
      const toast = useToast();
      toast.success(`Félicitations ! Vous avez débloqué le badge : ${badge.name} !`, {
        position: 'top-right',
        timeout: 5000,
      });
    },
    onCookiesAccepted() {
      this.showCookieBanner = false;
      this.initGoogleAnalytics();
    },
    onCookiesDeclined() {
      this.showCookieBanner = false;
    },
    initGoogleAnalytics() {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-P11Q16R736');
    },
    handleCookieConsent() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      if (cookiesAccepted === null) {
        this.showCookieBanner = true;
      } else if (cookiesAccepted === 'true') {
        this.initGoogleAnalytics();
      }
    },
    checkAuthenticationOnLoad() {
      console.log('App created, isAuthenticated:', this.isAuthenticated);
      this.$store.commit('setLoading', true);

      setTimeout(() => {
        const allowedRoutes = [
          'LandingPage',
          'ResetPassword',
          'ForgotPassword',
          'PolitiqueDeConfidentialite',
          'Feedback',
          'Register',
        ];
        if (!this.isAuthenticated && !allowedRoutes.includes(this.$route.name)) {
          this.$router.push('/login');
        }

        this.$store.commit('setLoading', false);
      }, 5000);
    },
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },
    retryConnection() {
      if (navigator.onLine) {
        this.isOnline = true;
        const toast = useToast();
        toast.success('Connexion rétablie avec succès !', {
          position: 'top-right',
          timeout: 3000,
        });
      } else {
        const toast = useToast();
        toast.error('Toujours hors ligne. Vérifiez votre connexion.', {
          position: 'top-right',
          timeout: 3000,
        });
      }
    },
  },
  created() {
    this.handleCookieConsent();
    this.checkAuthenticationOnLoad();

    // Écoute des événements de connexion
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
};
</script>

<style>
.connection-lost-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.v-card {
  animation: bounceIn 1s;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Transitions pour les vues */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Styles globaux */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #141414;
  font-family: 'Montserrat', sans-serif;
}

#app {
  width: 100%;
  min-height: 100vh;
  background-color: #141414;
  display: flex;
  flex-direction: column;
}

.v-main {
  flex: 1;
  background-color: #141414;
}

.authenticated-main {
  padding-top: 64px;
}
</style>
